.user-config-wrapper[data-v-e2bf728e] {
  width: 100%;
  height: 100%;
}
.user-config-wrapper .table-container[data-v-e2bf728e] {
    position: relative;
}
.user-config-wrapper .table-container .advanced-search-mask[data-v-e2bf728e] {
      width: 100%;
      height: 100%;
      border: solid 1px #edeff5;
      position: absolute;
      top: 63px;
      left: 0;
      z-index: 9;
      background: rgba(0, 0, 0, 0.5);
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.user-config-wrapper .table-container .advanced-search-mask .advanced-search-wrap[data-v-e2bf728e] {
        width: 100%;
        border: solid 1px #edeff5;
        background: #fff;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 8px 12px;
}
.user-config-wrapper[data-v-e2bf728e] .vxe-table .vxe-table--header {
    width: 100% !important;
}
.user-config-wrapper[data-v-e2bf728e] .vxe-table .vxe-table--body {
    width: 100% !important;
}
.user-config-wrapper[data-v-e2bf728e] .vxe-pager {
    height: 100%;
}
.user-config-wrapper[data-v-e2bf728e] .vxe-pager .vxe-pager--wrapper {
      padding-top: 10px;
}
.user-config-wrapper .display-flex[data-v-e2bf728e] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
[data-v-e2bf728e] .user-variable-dlg .box-card {
  margin-top: 10px;
}
[data-v-e2bf728e] .user-variable-dlg .box-card .cus-treeselect .vue-treeselect__control {
    height: 38px;
    line-height: 38px;
}
[data-v-e2bf728e] .user-variable-dlg .box-card .cus-treeselect .vue-treeselect__control .vue-treeselect__multi-value {
      margin-bottom: 3px;
}
[data-v-e2bf728e] .user-variable-dlg .box-card .cus-treeselect .vue-treeselect__control .vue-treeselect__single-value,[data-v-e2bf728e] .user-variable-dlg .box-card .cus-treeselect .vue-treeselect__control .vue-treeselect__placeholder {
      line-height: 38px;
}
[data-v-e2bf728e] .user-variable-dlg .box-card .cus-treeselect .vue-treeselect__control .vue-treeselect__multi-value {
      line-height: 24px;
}
.clearfix[data-v-e2bf728e] {
  color: #f4bb21 !important;
  font-size: var(--rootFontSize);
  font-weight: 700;
}
