.widget-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.widget-selector .widget-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.widget-selector .widget-item-label {
      width: 80px;
      text-align: right;
}
.widget-selector .widget-item-value {
      width: calc(100% - 80px);
      margin-left: 10px;
      text-align: left;
}
.widget-selector .widget-item-value .el-radio {
        margin-right: 10px;
}
.widget-selector .widget-item-value .el-radio .el-radio__label {
          padding-left: 0px;
}
.custom-tree-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #f1f4f6;
  padding: 10px;
  background-color: #f5f7fa;
}
.custom-tree-header-left {
    width: 160px;
    text-align: left;
    margin-left: 14px;
}
.custom-tree-header-right {
    width: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.custom-tree-header .custom-tree-item {
    width: 60px;
    text-align: center;
}
.custom-tree-wrapper {
  width: 100%;
  height: 100%;
  border-top: solid 1px #f1f4f6;
  border-bottom: solid 1px #f1f4f6;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.custom-tree-node {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.custom-tree-node-left {
    width: 160px;
    text-align: left;
    margin-left: 14px;
}
.custom-tree-node-right {
    width: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.custom-tree-node-right .el-radio__label {
      display: none;
}
.custom-tree-node .custom-tree-item {
    width: 60px;
    text-align: center;
    margin-right: 0px;
}
