.node-action-container[data-v-137ed7b0] {
  height: 100%;
}
.node-action-container .node-action-header[data-v-137ed7b0] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-top: 1px solid #f1f4f6;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: #f5f7fa;
}
.node-action-container .node-action-header .node-action-header-item[data-v-137ed7b0] {
      width: 120px;
      text-align: center;
}
.node-action-container .node-action-header .node-action-header-item[data-v-137ed7b0]:nth-of-type(4) {
        width: 200px;
}
.node-action-container .node-action-content[data-v-137ed7b0] {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.node-action-container .node-action-content .node-action-row[data-v-137ed7b0] {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      border-top: 1px solid #f1f4f6;
      padding: 4px 0px;
}
.node-action-container .node-action-content .node-action-row[data-v-137ed7b0]:first-of-type {
        border-top: none;
}
.node-action-container .node-action-content .node-action-row .node-action-item[data-v-137ed7b0] {
        width: 195px;
        text-align: center;
}
.node-action-container .node-action-content .node-action-row .node-action-item .el-form-item[data-v-137ed7b0] {
          margin-bottom: 0px;
}
.node-action-container .node-action-content .node-action-row .node-action-item[data-v-137ed7b0]:nth-of-type(4) {
          width: 200px;
}
.node-action-container .add-btn-wrapper[data-v-137ed7b0] {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px dotted #ccc;
    cursor: pointer;
}
.node-action-container .add-btn-wrapper i[data-v-137ed7b0] {
      font-size: var(--rootFontSize3);
      color: #409eff;
      line-height: inherit;
}
