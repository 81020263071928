.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-left .unselect-search-area[data-v-7a0a2210] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px;
  border: 1px solid #e8eaec;
  border-bottom: 0px;
}
.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-left .unselect-search-transition[data-v-7a0a2210] {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 180px;
  padding: 14px 0px;
  border: 1px solid #dcdee2;
  background-color: #fff;
}
.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-left .unselect-search-transition .unselect-search-transition-form[data-v-7a0a2210] {
    padding: 0px 14px;
}
.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-left .unselect-search-transition .unselect-search-transition-form .ivu-form-item[data-v-7a0a2210] {
      margin-bottom: 10px;
}
.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-left .unselect-search-transition .unselect-search-transition-form .ivu-form-item[data-v-7a0a2210] .ivu-input {
        height: 32px;
}
.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-left .unselect-search-transition .unselect-search-transition-footer[data-v-7a0a2210] {
    position: absolute;
    bottom: 4px;
    width: 100%;
    text-align: center;
    border-top: 1px solid #dcdee2;
    padding-top: 5px;
}
.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-left .unselect-search-transition .unselect-search-transition-footer .ivu-btn[data-v-7a0a2210] {
      margin-left: 14px;
}
.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-btn-area[data-v-7a0a2210] {
  height: 350px;
  padding-top: 250px;
}
.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-right .selected-search-area[data-v-7a0a2210] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px;
  border: 1px solid #e8eaec;
  border-bottom: 0px;
}
.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-right .vxe-table[data-v-7a0a2210] {
  width: 100%;
}
.el-tabs .el-tabs__content .el-tab-pane .user-transfer-container .user-transfer-right .vxe-table table[data-v-7a0a2210] {
    width: 100%;
}
.selected-names[data-v-7a0a2210] {
  width: 100%;
}
.search-row[data-v-7a0a2210] {
  margin-bottom: 10px;
}
.cus-placeholder[data-v-7a0a2210] {
  display: block;
  height: 30px;
  line-height: 30px;
  color: #c5c8ce;
  font-size: var(--rootFontSize);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 22px;
}
.ivu-tabs-bar[data-v-7a0a2210] {
  margin-bottom: 10px;
}
[data-v-7a0a2210] .vxe-table {
  width: 100%;
}
[data-v-7a0a2210] .vxe-table /deep/ table {
    width: 100%;
}
[data-v-7a0a2210] .ivu-form-item {
  margin-bottom: 0px;
}
[data-v-7a0a2210] .ivu-form-item {
  margin-bottom: 0px;
}
[data-v-7a0a2210] .vxe-table--empty-placeholder {
  top: 50% !important;
  height: 0 !important;
}
[data-v-7a0a2210] .vxe-table--body-wrapper {
  height: 420px;
  overflow-y: scroll;
}
[data-v-7a0a2210] .vxe-table--body-wrapper .vxe-table--body {
    width: 100% !important;
}
[data-v-7a0a2210] .vxe-table--body-wrapper .vxe-table--body .vxe-cell {
      width: 100% !important;
}
@media screen and (max-height: 1080px) and (min-height: 900px) {
.user-transfer-container .user-transfer-btn-area[data-v-7a0a2210] {
    height: 400px;
    padding-top: 200px;
}
[data-v-7a0a2210] .vxe-table--body-wrapper {
    height: 420px;
    overflow-y: scroll;
}
}
@media screen and (max-height: 899px) and (min-height: 768px) {
.user-transfer-container .user-transfer-btn-area[data-v-7a0a2210] {
    height: 300px;
    padding-top: 150px;
}
[data-v-7a0a2210] .vxe-table--body-wrapper {
    height: 300px;
    overflow-y: scroll;
}
}
@media screen and (max-height: 767px) {
.user-transfer-container .user-transfer-btn-area[data-v-7a0a2210] {
    height: 250px;
    padding-top: 250px;
}
[data-v-7a0a2210] .vxe-table--body-wrapper {
    height: 300px;
    overflow-y: scroll;
}
}
