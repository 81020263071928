.condition-container[data-v-0c96555c] {
  margin-top: 20px;
  padding: 4px 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.condition-container .add-btn-wrapper[data-v-0c96555c] {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px dotted #ccc;
    cursor: pointer;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.condition-container .add-btn-wrapper i[data-v-0c96555c] {
      font-size: var(--rootFontSize3);
      color: #409eff;
      line-height: inherit;
}
.condition-container .condition-wrapper[data-v-0c96555c] {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 1px dotted #ccc;
    margin-top: 10px;
}
.condition-container .condition-wrapper[data-v-0c96555c]:first-of-type {
      margin-top: 0px;
}
.condition-container .condition-wrapper .condtion-group-top[data-v-0c96555c] {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 4px 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      background: var(--mainThemeColor);
      color: #fff;
}
.condition-container .condition-wrapper .condtion-group-top .condtion-group-top-left[data-v-0c96555c] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.condition-container .condition-wrapper .condtion-group-top .condtion-group-top-left span[data-v-0c96555c] {
          margin-right: 14px;
}
.condition-container .condition-wrapper .condtion-group-top .condtion-group-top-right[data-v-0c96555c] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.condition-container .condition-wrapper .condtion-group-body[data-v-0c96555c] {
      width: 100%;
      padding: 0px 10px;
      margin-top: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.condition-container .condition-wrapper .condtion-group-body .condtion-form[data-v-0c96555c] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-top: 10px;
}
.condition-container .condition-wrapper .condtion-group-body .condtion-form[data-v-0c96555c]:first-of-type {
          margin-top: 0px;
}
.condition-container .condition-wrapper .condtion-group-body .condtion-form[data-v-0c96555c] .condtion-form-item {
          margin-bottom: 0px !important;
}
.condition-container .condition-wrapper .condtion-group-body .condtion-form[data-v-0c96555c] .condtion-form-item .el-select {
            width: 100%;
}
.condition-container .condition-wrapper .condtion-group-body .condtion-form[data-v-0c96555c] .condtion-form-item:first-of-type {
            width: 80px;
}
.condition-container .condition-wrapper .condtion-group-body .condtion-form[data-v-0c96555c] .condtion-form-item:nth-of-type(2) {
            width: calc(100% - 620px);
}
.condition-container .condition-wrapper .condtion-group-body .condtion-form[data-v-0c96555c] .condtion-form-item:nth-of-type(3) {
            width: 160px;
}
.condition-container .condition-wrapper .condtion-group-body .condtion-form[data-v-0c96555c] .condtion-form-item:nth-of-type(4) {
            width: 160px;
}
.condition-container .condition-wrapper .condtion-group-body .condtion-form[data-v-0c96555c] .condtion-form-item:last-of-type {
            width: 80px;
            text-align: right;
}
.condition-container .condition-wrapper .condtion-group-body .condtion-form[data-v-0c96555c]:last-of-type {
          margin-bottom: 10px;
}
[data-v-0c96555c] .edge-dialog {
  min-height: 200px;
}
[data-v-0c96555c] .edge-dialog .condition-form {
    padding: 30px 0px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect {
    line-height: 40px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect .vue-treeselect__placeholder {
      line-height: 40px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect .vue-treeselect__control {
      height: 40px !important;
      line-height: 40px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__single-value {
        line-height: 40px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value {
        height: 40px;
        line-height: 40px;
        margin-bottom: 0px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__multi-value-item-container {
          height: 36px;
          padding-top: 2px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__multi-value-item-container .vue-treeselect__multi-value-item {
            line-height: 30px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__limit-tip {
          height: 26px;
          padding-top: 2px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__limit-tip .vue-treeselect__limit-tip-text {
            line-height: 30px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__input-container {
          height: 30px;
          line-height: 30px;
}
[data-v-0c96555c] .edge-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__input-container .vue-treeselect__input {
            vertical-align: middle;
}
