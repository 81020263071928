.node-action-container[data-v-7a3d973c] {
  width: 100%;
  height: 100%;
}
.node-action-container .node-action-header[data-v-7a3d973c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-top: 1px solid #f1f4f6;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: #f5f7fa;
}
.node-action-container .node-action-header .node-action-header-item[data-v-7a3d973c] {
      width: 120px;
      text-align: left;
}
.node-action-container .node-action-header .node-action-header-item[data-v-7a3d973c]:nth-of-type(4) {
        width: 200px;
}
.node-action-container .node-action-tree[data-v-7a3d973c] {
    height: 300px;
    overflow-y: auto;
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.node-action-container .node-action-tree .node-action-row[data-v-7a3d973c] {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: left;
          -ms-flex-align: left;
              align-items: left;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      border-top: 1px solid #f1f4f6;
      padding: 4px 0px;
}
.node-action-container .node-action-tree .node-action-row[data-v-7a3d973c]:first-of-type {
        border-top: none;
}
.node-action-container .node-action-tree .node-action-row .node-action-item[data-v-7a3d973c] {
        width: 120px;
        text-align: left;
}
.node-action-container .node-action-tree .node-action-row .node-action-item[data-v-7a3d973c]:nth-of-type(4) {
          width: 200px;
}
.node-action-container .add-btn-wrapper[data-v-7a3d973c] {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px dotted #ccc;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: pointer;
    margin-top: 14px;
}
.node-action-container .add-btn-wrapper i[data-v-7a3d973c] {
      font-size: var(--rootFontSize3);
      color: #409eff;
      line-height: inherit;
}
[data-v-7a3d973c] .action-config-dlg .cus-divider .el-divider__text {
  color: #606266;
  font-weight: 500;
}
