.graph-container[data-v-3b29eb91] {
  width: 100%;
  height: 100%;
}
.graph-container[data-v-3b29eb91] .el-drawer__wrapper {
    z-index: 2001 !important;
}
.graph-container[data-v-3b29eb91] .el-drawer__body {
    height: calc(100% - 15px);
}
.graph-container[data-v-3b29eb91] .el-drawer__header {
    margin-bottom: 0px !important;
    padding: 15px 20px 0;
}
.graph-container .flow-node-tip[data-v-3b29eb91] {
    position: absolute;
    min-height: 100px;
    word-wrap: break-word;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.graph-container .flow-node-tip .flow-node-box[data-v-3b29eb91] {
      min-width: 200px;
      max-width: 300px;
      float: left;
      border: 1px solid #ddd;
      background: #fff;
}
.graph-container .flow-node-tip .flow-node-name[data-v-3b29eb91] {
      border-bottom: 1px solid #ddd;
      height: auto;
      line-height: 40px;
      padding: 0px 14px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.graph-container .flow-node-tip .flow-node-desc[data-v-3b29eb91] {
      padding: 5px 14px;
}
.flow-container[data-v-3b29eb91] {
  width: 100%;
  height: 100%;
  outline: none;
}
.flow-container .lf-control .el-icon-view[data-v-3b29eb91] {
    font-size: var(--rootFontSize2);
}
.flow-container .lf-control .el-icon-download[data-v-3b29eb91] {
    font-size: var(--rootFontSize2);
}
[data-v-3b29eb91] .el-divider__text {
  color: #f4bb21;
  font-weight: 700;
  font-size: var(--rootFontSize);
}
.custom-tree-node[data-v-3b29eb91] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: var(--rootFontSize);
  padding-right: 8px;
}
.box-card[data-v-3b29eb91] {
  margin-bottom: 15px;
}
.clearfix[data-v-3b29eb91] {
  font-size: var(--rootFontSize);
  font-weight: 700;
}
