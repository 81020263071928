.auto-operation-wrapper[data-v-75784cf4] {
  position: relative;
  width: 100%;
  min-height: 300px;
  padding: 14px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.auto-operation-wrapper .add-btn-wrapper[data-v-75784cf4] {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px dotted #ccc;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: pointer;
}
.auto-operation-wrapper .add-btn-wrapper i[data-v-75784cf4] {
      font-size: var(--rootFontSize3);
      color: #409eff;
      line-height: inherit;
}
.auto-operation-wrapper .operation-item-wrapper[data-v-75784cf4] {
    border: 1px solid #ccc;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-height: 200px;
    margin-bottom: 14px;
}
.auto-operation-wrapper .operation-item-wrapper .operation-item-top[data-v-75784cf4] {
      width: 100%;
      height: 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      background: var(--mainThemeColor);
      color: #fff;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.auto-operation-wrapper .operation-item-wrapper .operation-item-top .operation-item-title[data-v-75784cf4] {
        width: 210px;
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
        font-size: var(--rootFontSize);
}
.auto-operation-wrapper .operation-item-wrapper .operation-item-top .operation-item-icon[data-v-75784cf4] {
        width: 80px;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        cursor: pointer;
}
.auto-operation-wrapper .operation-item-wrapper .operation-item-top .operation-item-icon i[data-v-75784cf4] {
          font-size: var(--rootFontSize3);
          color: #fff;
          line-height: inherit;
          margin-right: 10px;
}
.auto-operation-wrapper .operation-item-wrapper .operation-item-content[data-v-75784cf4] {
      width: calc(100% - 20px);
      margin: 0px 10px;
}
.auto-operation-wrapper .operation-item-wrapper .operation-item-content .operation-param-wrapper[data-v-75784cf4] {
        margin: 10px 0px;
}
.auto-operation-wrapper .operation-item-wrapper .operation-item-content .operation-param-wrapper .cus-divider[data-v-75784cf4] {
          margin: 24px 10px;
}
.auto-operation-wrapper .operation-item-wrapper .operation-item-content .operation-param-wrapper .cus-divider .el-divider__text[data-v-75784cf4] {
            color: #606266;
            font-weight: 500;
}
[data-v-75784cf4] .el-form-item {
  margin-bottom: 0px;
}
