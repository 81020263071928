.flow-modal-header[data-v-0b6a27ed] {
  width: 100%;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.flow-modal-header-left[data-v-0b6a27ed] {
    font-size: var(--rootFontSize);
}
.flow-modal-header-right[data-v-0b6a27ed] {
    font-size: var(--rootFontSize);
}
.property-desciption[data-v-0b6a27ed] {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.property-desciption > label[data-v-0b6a27ed] {
    width: 100px;
    text-align: left;
}
.cus-divider .el-divider__text[data-v-0b6a27ed] {
  color: #606266;
  font-weight: 500;
}
.property-dialog[data-v-0b6a27ed] {
  padding: 0px 10px 10px 10px;
  height: calc(100% - 110px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.property-dialog .property-tabs[data-v-0b6a27ed] {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.property-dialog .property-tabs .property-tab-item[data-v-0b6a27ed] {
      height: calc(100% - 30px);
      overflow-y: auto;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.property-dialog .property-tabs[data-v-0b6a27ed] .el-tabs__content {
      height: calc(100% - 40px);
      overflow-y: scroll;
}
.property-dialog .form-widget-mapping[data-v-0b6a27ed] {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 8px;
}
.property-dialog .form-widget-mapping .sub-form-widget[data-v-0b6a27ed] {
      width: calc(50% - 50px);
}
.property-dialog .form-widget-mapping .sub-form-widget[data-v-0b6a27ed] .vue-treeselect__value-container {
        height: 38px;
        line-height: 38px;
}
.property-dialog .form-widget-mapping .sub-form-widget[data-v-0b6a27ed] .vue-treeselect__value-container .vue-treeselect__placeholder,
        .property-dialog .form-widget-mapping .sub-form-widget[data-v-0b6a27ed] .vue-treeselect__value-container .vue-treeselect__single-value {
          line-height: inherit;
}
.property-dialog .form-widget-mapping .target-flow-param[data-v-0b6a27ed] {
      width: calc(50% - 50px);
}
.property-dialog .form-widget-mapping .target-flow-param .el-select[data-v-0b6a27ed] {
        width: 100%;
}
.property-dialog .form-widget-mapping .mapping-tools[data-v-0b6a27ed] {
      width: 80px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
          justify-content: space-around;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.flow-modal__switch[data-v-0b6a27ed] {
  margin-top: 10px;
}
.flow-modal__switch[data-v-0b6a27ed] .el-collapse {
    border: 1px solid #ebeef5;
}
.flow-modal__switch[data-v-0b6a27ed] .el-collapse-item__header {
    background-color: #f5f7fa;
    padding: 0 10px;
}
.flow-modal__switch[data-v-0b6a27ed] .el-collapse-item__wrap {
    overflow: visible;
}
.flow-modal__switch[data-v-0b6a27ed] .el-collapse-item__content {
    padding: 10px;
}
.box-card[data-v-0b6a27ed] {
  margin-bottom: 10px;
}
.box-card[data-v-0b6a27ed] .el-card__header {
    padding: 0px 16px;
}
.box-card .box-card-header[data-v-0b6a27ed] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 60px;
}
.box-card[data-v-0b6a27ed] .cus-treeselect {
    font-size: var(--rootFontSize);
}
.box-card[data-v-0b6a27ed] .cus-treeselect .vue-treeselect__control {
      height: 38px;
      line-height: 38px;
}
.box-card[data-v-0b6a27ed] .cus-treeselect .vue-treeselect__control .vue-treeselect__multi-value {
        margin-bottom: 3px;
}
.box-card[data-v-0b6a27ed] .cus-treeselect .vue-treeselect__control .vue-treeselect__single-value,
      .box-card[data-v-0b6a27ed] .cus-treeselect .vue-treeselect__control .vue-treeselect__placeholder {
        line-height: 38px;
}
.box-card[data-v-0b6a27ed] .cus-treeselect .vue-treeselect__control .vue-treeselect__multi-value {
        line-height: 24px;
}
.box-card[data-v-0b6a27ed]:first-of-type {
    margin-top: 0px;
}
.clearfix[data-v-0b6a27ed] {
  color: #f4bb21 !important;
  font-size: var(--rootFontSize);
  font-weight: 700;
}
[data-v-0b6a27ed] .el-form-item {
  margin-bottom: 0px;
}
[data-v-0b6a27ed] .el-tabs__item {
  font-size: var(--rootFontSize);
}
[data-v-0b6a27ed] .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #f4bb21 !important;
}
.sortable-ghost[data-v-0b6a27ed] {
  opacity: 0.8;
  color: #fff !important;
  background: #409eff !important;
}
